<template>
  <div class="addActivity">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :footerShow="footerShow"
      @update="update"
    >
      <!-- <el-form-item
        label="选择园区"
        :rules="[{ required: false, message: '请选择园区', trigger: 'blur' }]"
        prop="authSpaceIds"
      >
        <v-cascader
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
        />
      </el-form-item> -->
      <el-form-item
        label="用户类别"
        prop="authSpaceTypes"
        :rules="[
          { required: true, validator: spaceTypeValid, trigger: 'blur' },
        ]"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in userTypeOps"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
            @change="checkChange"
          />
        </div>
      </el-form-item>
      <el-form-item
        v-show="form.authSpaceTypes !== undefined"
        label="发布范围"
        prop="authSpaceIds"
        :rules="[
          { required: false, message: '请选择发布范围', trigger: 'blur' },
        ]"
      >
        <v-cascader
          placeholder="默认表示全部"
          :width="220"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
        />
      </el-form-item>

      <el-form-item
        label="活动名称"
        :rules="[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ]"
        prop="activityName"
      >
        <v-input
          placeholder="请输入活动名称"
          v-model="form.activityName"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <!-- <el-form-item
        label="活动类型"
        :rules="[
          { required: true, message: '请选择活动类型', trigger: 'change' },
        ]"
        prop="activityCategory"
      >
        <v-select :options="activityTypeList" v-model="form.activityCategory" />
      </el-form-item> -->
      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'change' },
        ]"
        prop="isRecommend"
      >
        <v-select :options="isTopOps" v-model="form.isRecommend" />
      </el-form-item>

      <el-form-item
        v-if="form.isRecommend === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.sortIndex"
          min="1"
          type="number"
        ></v-input>
      </el-form-item>

      <!-- <el-form-item
        v-show="form.activityCategory == 20"
        label="承诺书内容"
        :rules="[
          {
            required: form.activityCategory == 20,
            message: '请选择活动类型',
            trigger: 'blur',
          },
        ]"
        prop="commitmentLetter"
      >
        <v-ueditor
          :maxLength="20000"
          v-model="form.commitmentLetter"
          :isShowImg="false"
        />
      </el-form-item> -->

      <el-form-item
        label="活动场景类型"
        :rules="[
          { required: true, message: '请选择活动场景类型', trigger: 'blur' },
        ]"
        prop="activitySceneType"
      >
        <v-radio
          :radioObj="activitySceneTypeRadio"
          v-model="form.activitySceneType"
          @change="changeActivitySceneType()"
        ></v-radio>
      </el-form-item>

      <el-form-item
        label="场景ID"
        :rules="[{ required: true, message: '请选择场景ID', trigger: 'blur' }]"
        prop="sceneIds"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in sceneIdsList"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
          />
        </div>
      </el-form-item>
      <el-form-item
        label="上报用场景类型"
        :rules="[
          {
            required: false,
            message: '请选择上报用场景类型',
            trigger: 'blur',
          },
        ]"
        prop="statisticsScenes"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in reportScenesList"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
          />
        </div>
      </el-form-item>

      <el-form-item
        label="是否允许报名多场"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="allowApplyMulti"
      >
        <v-radio
          :radioObj="radioApplyObj"
          v-model="form.allowApplyMulti"
          @change="changeApply"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="活动地点"
        :rules="[
          { required: false, message: '请输入活动地点', trigger: 'blur' },
        ]"
        prop="placeId"
      >
        <div style="display: flex">
          <v-select
            placeholder="请选择活动地点"
            :options="areaList"
            filterable
            clearable
            v-model="form.placeId"
            @change="selectPlace"
          />
          <div style="margin-left: 10px">
            <v-input
              v-if="form.placeId == ''"
              v-model="form.activityAddress"
              placeholder="请输入活动地点"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="活动场次"
        :rules="[
          { required: true, message: '请输入活动场次', trigger: 'blur' },
        ]"
        prop="scheduleList"
      >
        <div class="times">
          <div
            class="areaTimes"
            v-for="(item, index) in form.scheduleList"
            :key="index"
          >
            <div class="areaTimes-l">
              <div class="item">
                <div class="item-l">活动人数</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入活动人数',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.limitCount`"
                  >
                    <v-input
                      placeholder="请输入活动人数， 0-表示不限制"
                      v-model="item.limitCount"
                      :width="250"
                      min="0"
                      type="number"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">是否限制单次报名参与人数</div>
                <div class="item-r">
                  <!-- <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请选择是否限制单次报名参与人数',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.isLimit`"
                  > -->
                  <v-radio
                    :radioObj="radioObj"
                    v-model="item.isLimit"
                    @change="change($event, item)"
                  ></v-radio>
                  <!-- </el-form-item> -->
                  <div class="inputPeople" v-if="item.isLimit == 1">
                    <!-- <el-form-item
                      :rules="[
                        {
                          required: true,
                          message: '请输入限制人数',
                          trigger: 'blur',
                        },
                      ]"
                      :prop="`scheduleList.${index}.perLimitCount`"
                    > -->
                    <v-input
                      placeholder="请输入限制人数"
                      v-model="item.perLimitCount"
                      :width="160"
                      min="0"
                      :max="item.limitCount"
                      type="number"
                    ></v-input>
                    <!-- </el-form-item> -->
                    <!-- <span>(请输入限制人数)</span> -->
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-l">报名时间</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        type: 'array',
                        required: true,
                        message: '请选择报名时间',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.applyDate`"
                  >
                    <v-date-picker
                      v-model="item.applyDate"
                      type="datetimerange"
                    ></v-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">活动时间</div>
                <div class="item-r">
                  <el-form-item
                    :rules="[
                      {
                        type: 'array',
                        required: true,
                        message: '请选择活动时间',
                        trigger: 'blur',
                      },
                    ]"
                    :prop="`scheduleList.${index}.activityDate`"
                  >
                    <v-date-picker
                      v-model="item.activityDate"
                      type="datetimerange"
                    ></v-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="item-l">积分规则</div>
                <div class="item-r">
                  <v-select
                    clearable
                    :options="activityPointSettingList"
                    v-model="item.pointsSettingId"
                  />
                </div>
              </div>
            </div>
            <div class="areaTimes-r">
              <div class="opera">
                <v-button
                  text="删除"
                  v-if="index != 0"
                  @click="outRoom(item, index)"
                  type="warning"
                ></v-button>
                <v-button
                  v-if="index == form.scheduleList.length - 1"
                  text="新增"
                  @click="addRoom"
                ></v-button>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="活动图片"
        :rules="[
          { required: true, message: '请上传活动图片', trigger: 'blur' },
        ]"
        prop="activityPicture"
      >
        <div id="activityImg">
          <v-upload
            :limit="1"
            :imgUrls.sync="form.activityPicture"
            tips="建议上传686px*238px图片"
          />
        </div>
      </el-form-item>
      <el-form-item
        label="活动文本"
        :rules="[
          { required: false, message: '请输入活动文本', trigger: 'blur' },
        ]"
        prop="content"
      >
        <v-ueditor
          :maxLength="20000"
          v-model="form.content"
          :isShowImg="false"
        />
      </el-form-item>
      <el-form-item
        label="活动图片"
        :rules="[
          {
            required: false,
            message: '请上传活动图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="imageUrls"
      >
        <v-upload :limit="9" :imgUrls.sync="form.imageUrls" />
      </el-form-item>
      <el-form-item
        label="活动视频"
        :rules="[{ required: false, message: '请上传视频', trigger: 'blur' }]"
        prop="videoUrl"
      >
        <v-videoUpload :limit="1" :videoUrls.sync="form.videoUrl" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  getBuildListURL,
  activityDetail,
  activityEdit,
  activityPointSettingList,
  areaListUrl,
} from "./api.js";
import {
  activityTypeList,
  sceneIdsListMap,
  sceneIdsListOps,
  sceneIdsList,
  reportScenes,
  reportScenesMap,
  reportScenesOps,
  setIsTopOps,
  setUserTypeOps,
} from "./map.js";
export default {
  name: "addActivitySister",
  data() {
    return {
      isTopOps: setIsTopOps(),
      footerShow: true,
      reportScenesList: reportScenesOps(),
      activityTypeList,
      activitySceneTypeRadio: [
        { name: "1", value: "党建" },
        { name: "2", value: "公益" },
        { name: "3", value: "社群" },
        { name: "4", value: "商业" },
        { name: "5", value: "其他" },
      ],
      radioObj: [
        { name: "2", value: "不限制" },
        { name: "1", value: "限制" },
      ],
      radioApplyObj: [
        { name: "2", value: "否" },
        { name: "1", value: "是" },
      ],
      activityPointSettingList: [],
      submitConfig: {
        queryUrl: activityDetail,
        submitUrl: activityEdit,
      },
      areaList: [],
      form: {
        isRecommend: null,
        sortIndex: "",
        activityStatus: "",
        sceneIds: "",
        statisticsScenes: "",
        activitySceneType: "1",
        activityPicture: "",
        activityCategory: 103,
        commitmentLetter: "",
        allowApplyMulti: "1",
        communityId: null,
        activityId: null,
        activityName: "",
        activityAddress: "",
        placeId: "",
        limitCount: null,
        scheduleList: [
          {
            limitCount: "",
            perLimitCount: 0,
            applyDate: [],
            activityDate: [],
            pointsSettingId: null,
            isLimit: "2",
          },
        ],
        applyStartDate: "",
        applyEndDate: "",

        startDate: "",
        endDate: "",
        content: "",
        subImg: "",

        imageUrls: "",
        videoUrl: "",

        authSpaceIds: "", // 区域id集合
        authSpaceTypes: undefined, // 用户类别id
      },
      sceneIdsList: sceneIdsListOps(),
      // -----------------------
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
      userTypeOps: setUserTypeOps(),
    };
  },
  async mounted() {
    this.getAreaList();
    // 10 居民 11 商户
    await Promise.all([
      this.getBuildList(10),
      this.getBuildList(11),
      this.getBuildList("10,11"),
    ]);
    const { activityId } = this.$route.query;
    if (activityId !== undefined) {
      this.$refs.formPanel.getData({ activityId });
    } else {
      this.form.placeId = ""; // 由 null 修改为 ''，目前未找到 必须使用 null 的原因，null 导致代码报错
    }
    await this.getActivityPointSettingList();
    this.$setBreadList(activityId ? "编辑" : "新增");
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中类别集合
    sceneIdsList: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.sceneIds =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    reportScenesList: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.statisticsScenes =
          checkOps.length > 0 ? checkOps.map((v) => v.value).join(",") : "";
      },
      deep: true,
    },

    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },

    authSpaceTypes: {
      handler() {
        let types =
          this.form.authSpaceTypes !== undefined
            ? this.form.authSpaceTypes.split(",")
            : [];
        let result = [];
        if (types.includes("11") && types.includes("10")) {
          result = [...this.allOps];
        } else if (types.includes("11")) {
          result.push(...this.merchantsOps);
        } else if (types.includes("10")) {
          result.push(...this.residentsOps);
        }
        this.sectionOps = result;
      },
      immediate: true,
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
      },
      deep: true,
    },
  },
  methods: {
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      console.log(data);
      return data;
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        // 10 用户 11 商圈
        if (type === 10) {
          this.residentsOps = opt;
        } else if (type === 11) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
        // this.sectionOps = opt;
      }
    },
    selectPlace() {
      this.areaList.forEach((ele) => {
        if (ele.placeId == this.form.placeId) {
          this.form.activityAddress = ele.placeName;
        } else if (this.form.placeId.length == 0) {
          this.form.activityAddress = "";
        }
      });
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },

    spaceTypeValid(rule, value, callback) {
      if (value === undefined) {
        callback(new Error("请选择用户类别"));
      } else {
        callback();
      }
    },

    changeActivitySceneType() {},
    pickerOptions() {},
    outRoom(item, index) {
      this.form.scheduleList.splice(index, 1);
    },
    addRoom() {
      this.form.scheduleList.push({
        limitCount: "",
        perLimitCount: 0,
        applyDate: [],
        activityDate: [],
        pointsSettingId: null,
        isLimit: "2",
      });
    },
    changeApply(value) {
      console.log(value);
      this.form.allowApplyMulti = String(value);
    },
    change(value, item) {
      console.log(value, item);
      item.isLimit = String(value);
      this.$forceUpdate();
    },
    async getActivityPointSettingList() {
      this.activityPointSettingList = [];
      let res = await this.$axios.get(`${activityPointSettingList}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            this.activityPointSettingList.push({
              value: item.settingId.toString(),
              label: item.settingName,
            });
          });
        }
      } else {
        if (res.msg) {
          this.$message.error(res.msg);
        }
      }
    },
    getAreaList() {
      this.areaList = [];
      this.$axios.get(`${areaListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.placeName;
            item.value = item.placeId;
            this.areaList.push(item);
          });
          let obj = {
            label: "其他",
            value: "",
          };
          this.areaList.push(obj);
        } else {
        }
      });
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.authSpaceIds = [];
      // this.form.communityId = data.communityId;
      // const ids = this.form.communityId;
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      // this.spacePathIds.forEach((item) => {
      //   if (ids == item.id) {
      //     this.authSpaceIds = item.treeIds;
      //   }
      // });
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      let types = this.form.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        }
      });

      if (this.form.scheduleList) {
        this.form.scheduleList.forEach((ele) => {
          ele.pointsSettingId = ele.pointsSettingId
            ? String(ele.pointsSettingId)
            : "";
          if (ele.perLimitCount && Number(ele.perLimitCount) > 0) {
            ele.isLimit = "1";
          } else {
            ele.isLimit = "2";
          }
          ele.activityDate = [];
          ele.activityDate.push(new Date(ele.startDate));
          ele.activityDate.push(new Date(ele.endDate));
          ele.applyDate = [];
          ele.applyDate.push(new Date(ele.applyStartDate));
          ele.applyDate.push(new Date(ele.applyEndDate));
        });
      } else {
        this.form.scheduleList = [
          {
            limitCount: "",
            perLimitCount: 0,
            applyDate: [],
            activityDate: [],
            pointsSettingId: null,
            isLimit: "2",
          },
        ];
      }

      if (this.form.pointsSettingId) {
        if (this.form.pointsSettingId == 0) {
          this.form.pointsSettingId = null;
        } else {
          this.form.pointsSettingId = this.form.pointsSettingId.toString();
        }
      } else {
        this.form.pointsSettingId = null;
      }

      if (this.form.allowApplyMulti) {
        this.form.allowApplyMulti = String(this.form.allowApplyMulti);
      } else {
        this.form.allowApplyMulti = "1";
      }
      if (this.form.activitySceneType) {
        this.form.activitySceneType = String(this.form.activitySceneType);
      } else {
        this.form.activitySceneType = "1";
      }
      if (this.form.sceneIds) {
        let types = this.form.sceneIds.split(",");
        this.sceneIdsList.forEach((v) => {
          if (types.includes(v.value)) {
            v.checked = true;
          }
        });
      }

      if (this.form.statisticsScenes) {
        let scenesTypes = this.form.statisticsScenes.split(",");
        this.reportScenesList.forEach((v) => {
          if (scenesTypes.includes(v.value)) {
            v.checked = true;
          }
        });
      }
      if (this.form.activityStatus == 6) {
        this.footerShow = false;
      }
    },
    submitBefore() {
      // if (this.form.communityId == undefined || this.form.communityId == null) {
      //   this.$message("请选择园区");
      //   return;
      // }
      if (this.form.placeId == "" && this.form.activityAddress == "") {
        this.$message("请输入活动地点");
        return;
      }
      this.form.scheduleList.forEach((ele) => {
        if (!ele.activityDate) {
          ele.startDate = "";
          ele.endDate = "";
        }
        if (!ele.applyDate) {
          ele.applyStartDate = "";
          ele.applyEndDate = "";
        }
        ele.applyStartDate =
          ele.applyDate.length > 0
            ? ele.applyDate[0].format("yyyy-MM-dd HH:mm")
            : "";
        ele.applyEndDate =
          ele.applyDate.length > 0
            ? ele.applyDate[1].format("yyyy-MM-dd HH:mm")
            : "";
        ele.startDate =
          ele.activityDate.length > 0
            ? ele.activityDate[0].format("yyyy-MM-dd HH:mm")
            : "";
        ele.endDate =
          ele.activityDate.length > 0
            ? ele.activityDate[1].format("yyyy-MM-dd HH:mm")
            : "";
      });
      // this.form.scheduleList.forEach((v) => {
      //   v.applyStartDate = v.applyDate ? v.applyDate[0] : "";
      //   v.applyEndDate = v.applyDate ? v.applyDate[1] : "";
      //   v.startDate = v.activityDate ? v.activityDate[0] : "";
      //   v.endDate = v.activityDate ? v.activityDate[1] : "";
      // });
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
  /deep/ .el-form-item__label {
    width: 160px !important;
  }
  .inputPeople {
    display: flex;
  }
  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .areaTimes-l {
      margin-right: 20px;
    }
    .areaTimes-r {
      height: 46px;
      /deep/ .v-button {
        margin-right: 10px;
      }
    }
    .item {
      display: flex;
      align-content: center;
      margin: 20px 0;
      .item-l {
        margin-right: 10px;
      }
      .item-r {
        display: flex;
      }
    }
  }
}
</style>
